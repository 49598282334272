<template>
  <div>
    <h1 class="main-header" v-translate translate-context="customer_success.search">User Detail</h1>
    <CCard v-if="user">
      <CCardBody class="p-0">
        <CContainer fluid class="grid-stripped">
          <PFRow :label="$pgettext('customer_success.search','User ID')">
            {{ user.id }}
          </PFRow>
          <PFRow :label="$pgettext('customer_success.user_detail','E-Mail')">
            {{ user.email }}
          </PFRow>
          <PFRow :label="$pgettext('customer_success.user_detail','Reactivate User')">
            <PButton @click="onReactivate">Reactivate User</PButton>
            Active: {{ user.is_active }}
          </PFRow>
          <PFRow :label="$pgettext('customer_success.user_detail','Dashboard Access')">
            <PButton @click="onEnableDashboard">Enable for Dashboard use</PButton>
            Enabled: {{ user.is_pica_dashboard_user }}
          </PFRow>
          <PFRow :label="$pgettext('customer_success.user_detail','Change Password')">
            <CInput v-model="reset.password" type="text"/>
            <PButton @click="onChangePassword">Change Password</PButton>
          </PFRow>
          <PFRow :label="$pgettext('customer_success.user_detail','Checkins')">
            <div v-for="checkin in user.checkins" :key="checkin.id">
              <p>Is Master: {{ checkin.is_master }}</p>
              <p>Pica Service: {{ checkin.pica_service }}</p>
              <p>Created At: {{ checkin.created_at }}</p>
            </div>
          </PFRow>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PButton from "@/components/PButton.vue";

export default {
  name: 'UserDetail',
  components: {PButton, PFRow},
  data() {
    return {
      reset: {
        password: '',
      }
    }
  },
  computed: {
    ...mapGetters('customerSuccess', [
      'user',
    ]),
  },
  async mounted() {
    await this.fetchUser(this.$route.params.id)
  },
  methods: {
    ...mapActions('customerSuccess', [
      'fetchUser',
      'reactivate',
      'changePassword',
      'enableDashboard',
    ]),
    onChangePassword() {
      if (this.reset.password.length < 3)
        return this.notifyError('Password must be at least 3 characters long')

      this.changePassword(this.reset.password)
      this.reset.password = ''
      this.notifySuccess('Password changed')
    },
    onReactivate() {
      this.reactivate()
      this.notifySuccess('User reactivated')
    },
    onEnableDashboard() {
      this.enableDashboard()
      this.notifySuccess('User enabled for Dashboard')
    }
  }
}
</script>